import React from 'react'

export const Footer = () => {
    return (
        <>
            Pagina web 20k dios WEB &copy: Master en 20k

        </>
    )
}
