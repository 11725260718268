import React from 'react'
import ImagenesCarrusel from './layaoutGenericos/Carrusel/ImagenesCarrusel'


export const Inicio = () => {


  
  return (
    <>
    {/* <ImagenesCarrusel/> */}
    Inicio
    </>
  )
}
