import React from 'react'
import { Customer } from './Customer'
import { Box } from '@mui/material'
import { Pedidos } from './Pedidos'

export const HistoriaDePedidos = () => {
    return (
        <>
        <Customer/>
        <Pedidos/>
        </>

    )
}
