export const Global = {
    url: "https://whast-tcg-backend.onrender.com/carta/",
    urlUser:"https://whast-tcg-backend.onrender.com/user/",
    urlGlobal:"https://whast-tcg-backend.onrender.com/",
    
}


// export const Global = {
    // url: "http://localhost:3900/carta/",
    // urlUser:"http://localhost:3900/user/",
    // urlGlobal:"http://localhost:3900/",
    
// }
